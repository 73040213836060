import { Cart, LineItem } from '@commerce/types/cart';
import { Product } from '@commerce/types/product';
import { CheckoutSection } from '@components/checkout/OnePageCheckout/Sections/enums/CheckoutSection';
import { USER_LOGGED_IN_SESSION_STORAGE } from '@framework/const';
import { getStorage } from '@lib/browser-storage';

const crypto = require('crypto');

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS as string;

export type GTagEvent = {
  // TODO replace these with enums once they're specified
  action: string;
  category: string;
  label?: string;
  amount?: number;
};

export enum GTagCommerceAction {
  ADD_TO_CART = 'add_to_cart',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT_PROGRESS = 'checkout_progress',
  LOGIN = 'login',
  LIFE360_OTP = 'life360_otp',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  REMOVE_FROM_CART = 'remove_from_cart',
  SELECT_CONTENT = 'select_content',
  SET_CHECKOUT_OPTION = 'set_checkout_option',
  SIGN_UP = 'sign_up',
  VIEW_CART = 'view_cart',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_REFUND = 'view_refund',
  OPEN_SECTION = 'checkout_section_opened',
  SECTION_COMPLETED = 'checkout_section_completed',
  EXPRESS_PAYMENT_BUTTON_CLICKED = 'express_payment_button_clicked',
  REDIRECT_TO_AMAZON = 'redirect_to_amazon',
}

export type GTagCommerceEvent = {
  action: GTagCommerceAction;
  payload: any;
};

export type GAProduct = {
  productId?: string;
  quantity?: number;
  productName?: string;
  brand?: string;
  sku?: string;
};

const EXCLUDED_QUERY_PARAMS = ['piitoken', 'token', 'code'];

export const hashEmail = (email: string) => {
  return crypto.createHash('sha256').update(email).digest('base64');
};

const removeTokensFromQuery = (pagePath: URL | string): URL | string => {
  // pick up everything after the ?
  const query = pagePath.toString().split('?')[1];
  if (query) {
    const params = new URLSearchParams(query);
    for (const exclusion of EXCLUDED_QUERY_PARAMS) {
      if (params.has(exclusion)) {
        params.delete(exclusion);
      }
    }
    return `${(pagePath as string).split('?')[0]}?${encodeURI(params.toString())}`;
  }
  return pagePath;
};

export const pageView = (url: URL | string): void => {
  if (window?.gtag) {
    window.gtag('event', 'page_view', {
      page_location: removeTokensFromQuery(url),
      page_path: removeTokensFromQuery(url),
    });
  }
};

export const trackMissedBrowserLocale = (browserLocale: string): void => {
  if (window?.gtag) {
    window.gtag('event', 'missed_browser_locale', {
      browser_locale: browserLocale,
    });
  }
};

export const trackEvent = ({ action, category, label, amount }: GTagEvent): void => {
  const userLoggedIn = getStorage(USER_LOGGED_IN_SESSION_STORAGE);
  if (window?.gtag) {
    window.gtag('event', action, {
      event_category: category, // Aligned with GA4's parameters
      event_label: label, // Aligned with GA4's parameters
      login_status: userLoggedIn ? 'logged_in' : 'logged_out', // GA4 context
      value: amount,
    });
  }
};

export const trackCommerce = ({ action, payload }: GTagCommerceEvent): void => {
  if (window?.gtag) {
    window.gtag('event', action, payload);
  }
};

const formatLineItems = (lineItems: LineItem[] = []) => {
  return lineItems.map((item) => {
    return {
      item_id: item.variant.sku,
      item_name: item.variant.name,
      affiliation: 'Tile eCommerce',
      price: item.variant.price,
      quantity: item.quantity,
    };
  });
};

const getGtagCommercePayload = (cart: Cart) => ({
  currency: cart?.currency.code,
  coupon: cart?.coupons.map((coupon) => coupon.code).join(''),
  value: cart?.totalPrice,
  items: formatLineItems(cart?.lineItems),
});

export const trackGA4AddShippingInfoEvent = (cart: Cart | null | undefined, shippingMethod: string) => {
  if (cart) {
    trackCommerce({
      action: GTagCommerceAction.ADD_SHIPPING_INFO,
      payload: {
        ...getGtagCommercePayload(cart),
        shipping_tier: shippingMethod,
      },
    });
  }
};

export const trackGA4AddPaymentInfoEvent = (cart: Cart | null | undefined, paymentMethod: string) => {
  if (cart) {
    trackCommerce({
      action: GTagCommerceAction.ADD_PAYMENT_INFO,
      payload: {
        ...getGtagCommercePayload(cart),
        payment_type: paymentMethod,
      },
    });
  }
};

export const trackGA4PaymentBtnClicked = (cart: Cart | null | undefined, paymentType: string) => {
  if (cart) {
    trackCommerce({
      action: GTagCommerceAction.EXPRESS_PAYMENT_BUTTON_CLICKED,
      payload: {
        ...getGtagCommercePayload(cart),
        express_payment_type: paymentType,
      },
    });
  }
};

export const trackGA4SectionEvent = (
  cart: Cart | null | undefined,
  section: CheckoutSection,
  action: 'open' | 'completed'
) => {
  if (cart) {
    trackCommerce({
      action: action === 'completed' ? GTagCommerceAction.SECTION_COMPLETED : GTagCommerceAction.OPEN_SECTION,
      payload: {
        ...getGtagCommercePayload(cart),
        section,
      },
    });
  }
};

export const trackGA4AmazonRedirectEvent = (sku: string, url: string): void => {
  if (url && sku) {
    trackCommerce({
      action: GTagCommerceAction.REDIRECT_TO_AMAZON,
      payload: {
        sku,
        url,
      },
    });
  }
};

export const trackGA4AddToCartEvent = (product: GAProduct, cart: Cart | null | undefined): void => {
  if (product && cart) {
    const { productId, quantity = 1, productName, sku } = product;
    const pathName = window.location.pathname;
    const { lineItems, currency } = cart;
    const lineItemProduct = lineItems.find((item: any) => item.productId === productId);
    if (lineItemProduct) {
      trackCommerce({
        action: GTagCommerceAction.ADD_TO_CART,
        payload: {
          currency: currency.code,
          pathName,
          value: lineItemProduct.variant.price, // Calculate total value
          items: [
            {
              item_name: productName,
              item_id: sku,
              affiliation: 'Tile eCommerce',
              price: lineItemProduct.variant.price,
              quantity,
            },
          ],
        },
      });
    }
  }
};

export const trackGA4RemoveFromCartEvent = (product: GAProduct, cart: Cart | null | undefined): void => {
  if (product && cart) {
    const { productId, quantity = 1, productName, sku } = product;
    const { lineItems, currency } = cart;
    const lineItemProduct = lineItems.find((item: any) => item.productId === productId);
    if (lineItemProduct) {
      trackCommerce({
        action: GTagCommerceAction.REMOVE_FROM_CART,
        payload: {
          currency: currency.code,
          value: lineItemProduct.variant.price, // Calculate total value
          items: [
            {
              item_name: productName,
              item_id: sku,
              affiliation: 'Tile eCommerce',
              price: lineItemProduct.variant.price,
              quantity,
            },
          ],
        },
      });
    }
  }
};

export const trackGA4UpdateCartEvent = (
  productId: string | null | undefined,
  cart: Cart | null | undefined,
  oldQuantity: number | null | undefined
): void => {
  if (productId && cart && oldQuantity) {
    const { lineItems } = cart;
    const lineItemProduct = lineItems.find((lineItem: any) => lineItem.productId === productId);
    if (lineItemProduct) {
      if (oldQuantity > lineItemProduct.quantity) {
        trackGA4RemoveFromCartEvent(
          {
            productId,
            quantity: oldQuantity - lineItemProduct.quantity,
            productName: lineItemProduct.variant.name,
            sku: lineItemProduct.variant.sku,
          },
          cart
        );
      } else {
        trackGA4AddToCartEvent(
          {
            productId,
            quantity: lineItemProduct.quantity - oldQuantity,
            productName: lineItemProduct.variant.name,
            sku: lineItemProduct.variant.sku,
          },
          cart
        );
      }
    }
  }
};

export const trackGA4RemoveItemEntirelyFromCartEvent = (input: any): void => {
  if (input.productId && input.quantity && input.variant.sku) {
    trackCommerce({
      action: GTagCommerceAction.REMOVE_FROM_CART,
      payload: {
        currency: input.currency, // Default currency, adjust as needed
        value: input.variant.price, // Calculate total value
        items: [
          {
            item_name: input.variant.name,
            item_id: input.variant.sku,
            affiliation: 'Tile eCommerce',
            price: input.variant.price,
            quantity: input.quantity,
          },
        ],
      },
    });
  }
};

export const trackGA4BeginCheckoutEvent = (cart: Cart | null | undefined): void => {
  if (cart) {
    trackCommerce({
      action: GTagCommerceAction.BEGIN_CHECKOUT,
      payload: getGtagCommercePayload(cart),
    });
  }
};

export const trackGA4AViewCartEvent = (cart: Cart | null | undefined) => {
  if (cart) {
    trackCommerce({
      action: GTagCommerceAction.VIEW_CART,
      payload: getGtagCommercePayload(cart),
    });
  }
};

export const trackGA4AViewItemEvent = (product: Product) => {
  trackCommerce({
    action: GTagCommerceAction.VIEW_ITEM,
    payload: {
      currency: product.price.currencyCode,
      value: product.price.value,
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          affiliation: 'Tile eCommerce',
          price: product.price.value,
        },
      ],
    },
  });
};

export const trackGA4ALoginEvent = (userId: string, method: string = 'Tile Auth') => {
  trackCommerce({
    action: GTagCommerceAction.LOGIN,
    payload: {
      method,
      userId,
    },
  });
};

export const trackGA4ASignupEvent = (method: string = 'Tile Auth') => {
  trackCommerce({
    action: GTagCommerceAction.SIGN_UP,
    payload: {
      method,
    },
  });
};

export const trackGA4AOTPEvent = (eventName: string, payload: Record<string, unknown>) => {
  trackCommerce({
    action: GTagCommerceAction.LIFE360_OTP,
    payload: {
      event_name: eventName,
      ...payload,
    },
  });
};

export const setUserId = (userId: string): void => {
  if (window?.gtag && GA_TRACKING_ID) {
    window.gtag('config', GA_TRACKING_ID, {
      user_id: userId,
      send_page_view: false,
    });
  }
};

export const trackNavBarEvent = (label: string): void => {
  trackEvent({ action: 'Nav Bar Click', category: 'Nav Bar', label });
};

export const trackFooterEvent = (label: string): void => {
  trackEvent({ action: 'Footer Click', category: 'Footer', label });
};

export const trackSearchEvent = (label: string): void => {
  trackEvent({ action: 'Footer Click', category: 'Search', label });
};
