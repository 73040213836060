const euTileLocales = ['en-eu', 'en-ie', 'en-fr', 'en-de', 'en-at', 'en-be', 'en-fi', 'en-ch', 'en-nl'];
const euL360Locales = ['nl-nl'];
const euStoreLocales = process.env.NEXT_PUBLIC_IS_LIFE360 === 'true' ? euL360Locales : euTileLocales;

module.exports = {
  euStoreLocales: process.env.NEXT_PUBLIC_IS_LIFE360_INTL === 'true' ? [] : [...euStoreLocales],
  i18n: {
    // add locales/{{locale}}/{{namespace}}.json files (empty file is ok) anytime a locale key is added
    // the fallback will only handle missing keys, not file
    locales:
      process.env.NEXT_PUBLIC_IS_LIFE360_INTL === 'true'
        ? ['en-us']
        : ['en-us', 'en-ca', 'en-au', 'en-nz', 'en-gb', ...euStoreLocales],
    defaultLocale: 'en-us',
  },
  localePath: './locales',
  ns: [
    'account',
    'auth',
    'blog',
    'cart',
    'checkout',
    'common',
    'countries',
    'home',
    'languages',
    'product',
    'plans',
    'careers',
  ],
  defaultNS: 'common',
  lowerCaseLng: true, // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
};
